<template>
  <div v-if="section">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Label</label>
          <input v-model="section.Label" type="text" class="form-control"  />
        </div>
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BackgroundColor" label="Background" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.BorderColor" label="Border color" />
      </div>
      <div class="col-md-6">
        <ColorInput v-model="section.Color" label="Color" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Roudness" label="Corner roudness" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.FontSize" label="Font size" />
      </div>
      <div class="col-md-6">
        <NumberInput v-model="section.Padding" label="Padding" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from '../../Coupons/Components/ColorInput'
import NumberInput from '../../Coupons/Components/NumberInput'

export default {
  components: {
    ColorInput,
    NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style></style>
